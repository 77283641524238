import Splide from '@splidejs/splide';
import {windowLoaded} from "../../utilities/domready";

interface Window {
    Event : Event;
    CustomEvent(event : any, params : any) : Event;
}
interface Event {
    prototype : Event;
}
declare var CustomEvent: {
    new (event : string, detail : any) : CustomEvent;
}

export function initSingleReco(selector:string, perPage?:number) {
    windowLoaded(() => {
        if(document.querySelector(selector)) {
            new Splide(selector, {
                perPage: perPage || 7,
                perMove: perPage || 7,
                gap: '1.25rem',
                rewind: false,
                trimSpace: 'move',
                pagination: false,
                drag: window.innerWidth < 992,
                arrowPath: 'M14.7,31.6c-0.2,0-0.5,0-0.7-0.2c-0.4-0.4-0.4-0.9,0-1.3l10-10L13.8,9.9c-0.4-0.4-0.4-0.9,0-1.3c0.4-0.4,0.9-0.4,1.3,0l11.6,11.6L15.4,31.5C15.2,31.6,14.9,31.6,14.7,31.6z',
                breakpoints: {
                    1680: {
                        perPage: perPage - 1 || 4,
                        perMove: perPage - 1 || 4,
                        gap: '.625rem'
                    },
                    1280: {
                        perPage: perPage - 2 || 4,
                        perMove: perPage - 2 || 4,
                        gap: '.625rem'
                    },
                    992: {
                        perPage: perPage - 3 || 4,
                        perMove: perPage - 3 || 4,
                        gap: '.625rem'
                    },
                    767: {
                        perPage: 3,
                        perMove: 3,
                        gap: '.625rem'
                    },
                    600: {
                        perPage: 2,
                        perMove: 2,
                        gap: '.625rem'
                    },
                },
            }).mount()
        }
    });
}

export function productColors() {
    windowLoaded(() => {
        let sliderContainer = document.querySelectorAll('.splide-colors');
        for (let i = 0; i < sliderContainer.length; i++) {
            const noSlide = sliderContainer[i].querySelector('.colors-center');

            new Splide(sliderContainer[i] as HTMLElement, {
                perPage: 4,
                perMove: 3,
                pagination: false,
                arrows: !noSlide,
                keyboard: false,
                classes: {
                  arrows: 'splide__arrows slider-controls',
                  prev  : 'splide__arrow--prev prev',
                  next  : 'splide__arrow--next next',
                },
                breakpoints: {
                    480: {
                        perPage: 3,
                    },
                },
            }).mount();
        }
    });
}

export class Slider {
    splideOptions: any;

    //Listening for custom recoLoaded event, fired after ajax response insertion
    ajaxListener() {
        window.addEventListener('recoLoaded', function(e) {
            windowLoaded(() => {
                // selector and perPage have to be separated by ";" and be assigned to event.details
                const details = (<any>e).detail.split(';');
                initSingleReco(details[0], details[1] || 7);
            });
        })
    }

    defaultReco() {
        windowLoaded(() => {
            let sliderContainer = document.querySelectorAll('.reco-slider');
            for (let i = 0; i < sliderContainer.length; i++) {
                new Splide(sliderContainer[i] as HTMLElement, {
                    perPage: 6,
                    perMove: 5,
                    gap: '1.25rem',
                    rewind: false,
                    trimSpace: 'move',
                    pagination: false,
                    drag: window.innerWidth < 992,
                    slideFocus: true,
                    arrowPath: 'M14.7,31.6c-0.2,0-0.5,0-0.7-0.2c-0.4-0.4-0.4-0.9,0-1.3l10-10L13.8,9.9c-0.4-0.4-0.4-0.9,0-1.3c0.4-0.4,0.9-0.4,1.3,0l11.6,11.6L15.4,31.5C15.2,31.6,14.9,31.6,14.7,31.6z',
                    breakpoints: {
                        1024: {
                            perPage: 4,
                            perMove: 4,
                            gap: '.625rem',
                        },
                        768: {
                            perPage: 3,
                            perMove: 3,
                            gap: '.625rem',
                        },
                        640: {
                            perPage: 2,
                            perMove: 2,
                            gap: '.625rem',
                        },
                        320: {
                            perPage: 1,
                            perMove: 1,
                            gap: '.625rem',
                        }
                    },
                }).mount();
            }
        });
    }
    CategoryAdvisorSlider() {
        windowLoaded(() => {
            let swiperContainer = document.getElementById('categoryAdvisor');
            if(swiperContainer != null) {
                new Splide('#categoryAdvisor', {
                    perPage: 6,
                    perMove: 1,
                    gap: '1.25rem',
                    rewind: false,
                    trimSpace: 'move',
                    pagination: false,
                    drag: window.innerWidth < 992,
                    arrowPath: 'M14.7,31.6c-0.2,0-0.5,0-0.7-0.2c-0.4-0.4-0.4-0.9,0-1.3l10-10L13.8,9.9c-0.4-0.4-0.4-0.9,0-1.3c0.4-0.4,0.9-0.4,1.3,0l11.6,11.6L15.4,31.5C15.2,31.6,14.9,31.6,14.7,31.6z',
                    breakpoints: {
                        1024: {
                            perPage: 4,
                            perMove: 1,
                            gap: '.625rem',
                        },
                        768: {
                            perPage: 3,
                            perMove: 1,
                            gap: '.625rem',
                        },
                        640: {
                            perPage: 2,
                            perMove: 1,
                            gap: '.625rem',
                        }
                    },
                }).mount()
            }
        });
    }
    /**
     * Manually updates the arrow visibility for all reco-slider Splide instances.
     */
    updateSplideArrows(splide: Splide, rootId = '') {
        if (!splide?.root?.id?.startsWith('reco-slider')) return;

        setTimeout(() => {
            const { prev, next } = splide.Components.Arrows.arrows;

            prev?.toggleAttribute('disabled', splide.index === 0);
            next?.toggleAttribute('disabled', splide.index >= splide.Components.Controller.getEnd());
        }, 50);
    }

    initializeWithImportedOptions() {
        this.splideOptions = (<any>window)['splideOptions'];
        if(typeof this.splideOptions !== 'undefined' && this.splideOptions.length > 0) {
            for (let k = 0; k < this.splideOptions.length; k++) {
                const options = this.splideOptions[k]?.options ?? {};
                const selector = this.splideOptions[k]?.selector;

                //Disable Drag if Slider contains Nav-Image Widgets, or if viewport width < 992px
                if (document.querySelectorAll(selector + ' .widget-nav-image').length > 0 || window.innerWidth > 991) {
                    options.drag = false;
                }

                //Initiate Splide Slider with external Options
                const splide = new Splide(selector, options);
                if (splide) {
                    // manual fix for splide slider with broken arrow buttons
                    splide.on('ready', () => this.updateSplideArrows(splide));
                    splide.on('mounted moved updated', () => this.updateSplideArrows(splide));

                    splide.mount();
                }
                //Create Mount Event inline JS can listen to, attach mounted Splide Instance to splideOptions Object
                interface Window {
                    CustomEvent: CustomEvent;
                }

                (function () {
                    function CustomEvent ( event:string, params:any ) {
                        params = params || { bubbles: false, cancelable: false, detail: undefined };
                        var evt:CustomEvent = <any>document.createEvent( 'CustomEvent' );
                        evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
                        return evt;
                    }

                    CustomEvent.prototype = Event.prototype;

                    window.CustomEvent = <any>CustomEvent;

                    (<any>window).dispatchEvent(new (CustomEvent as any)('splideInitialized', { detail: k }));
                })();
                (<any>window).splideOptions[k].instance = splide;

                //If Slider contains Nav-Image Widgets, set equal scroll positions on button bars on slide change (if they have the same widths/contents)
                if(splide.root.querySelectorAll('.widget-nav-image').length > 0) {
                    splide.on('move', function(newSlide, oldSlide) {
                        if(splide.Components.Elements.slides[newSlide].querySelector('.hw-buttons-container') && splide.Components.Elements.slides[oldSlide].querySelector('.hw-buttons-container')) {
                            const newSlideButtons = splide.Components.Elements.slides[newSlide].querySelector('.hw-buttons-container');
                            const oldSlideButtons = splide.Components.Elements.slides[oldSlide].querySelector('.hw-buttons-container');
                            if(newSlideButtons.clientWidth == oldSlideButtons.clientWidth) {
                                newSlideButtons.scrollLeft = oldSlideButtons.scrollLeft;
                            }
                        }
                    })
                }
            }
        }
    }

    init() {
        this.ajaxListener();
        this.defaultReco();
        this.CategoryAdvisorSlider();
        this.initializeWithImportedOptions();
    }
}