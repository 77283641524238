function matching(el: any, selector: string) {
    return el.matches ? el.matches(selector) : el.msMatchesSelector(selector);
}

export function siblings(elem: Element, selector?: string): Array<Element> {
    let siblings = [];
    let sibling = elem.parentNode.firstElementChild;

    while (sibling) {
        if (sibling.nodeType === 1 && sibling !== elem) {
            if (selector) {
                if (matching(sibling, selector)) {
                    siblings.push(sibling);
                }
            } else {
                siblings.push(sibling);
            }
        }
        sibling = sibling.nextElementSibling
    }

    return siblings;
}

export function parents(elem: Element, selector?: string): Array<Element> {
    let parents = [];
    let child = elem;

    while (child) {
        if (selector) {
            if (matching(child, selector)) {
                parents.push(child);
            }
        } else {
            parents.push(child);
        }

        child = child.parentElement;
    }

    return parents;
}

export function childrens(elem: Element, selector?: string): Array<Element> {
    let childrens = [];
    let child = elem.firstElementChild;

    while (child) {
        if (selector) {
            if (matching(child, selector)) {
                childrens.push(child);
            }
        } else {
            childrens.push(child);
        }

        child = child.nextElementSibling;
    }

    return childrens;
}