export let backendFieldMapping: any = [];
backendFieldMapping['email'] = 'login.email';

backendFieldMapping['addressList[0].name'] = 'mainAddress.name';
backendFieldMapping['addressList[0].title'] = 'mainAddress.title';
backendFieldMapping['addressList[0].givenName'] = 'mainAddress.givenName';
backendFieldMapping['addressList[0].familyName'] = 'mainAddress.familyName';
backendFieldMapping['addressList[0].street'] = 'mainAddress.street';
backendFieldMapping['addressList[0].postalCode'] = 'mainAddress.postalCode';
backendFieldMapping['addressList[0].city'] = 'mainAddress.city';
backendFieldMapping['addressList[0].countryCode'] = 'mainAddress.countryCode';
backendFieldMapping['addressList[0].email'] = 'login.email';
backendFieldMapping['addressList[0].phone'] = 'mainAddress.phone';
backendFieldMapping['addressList[0].vatId'] = 'mainAddress.vatId';

backendFieldMapping['addressList[1].name'] = 'deliveryAddress.name';
backendFieldMapping['addressList[1].street'] = 'deliveryAddress.street';
backendFieldMapping['addressList[1].postalCode'] = 'deliveryAddress.postalCode';
backendFieldMapping['addressList[1].city'] = 'deliveryAddress.city';
backendFieldMapping['addressList[1].countryCode'] = 'deliveryAddress.countryCode';
backendFieldMapping['addressList[1].email'] = 'deliveryAddress.email';
backendFieldMapping['addressList[1].phone'] = 'deliveryAddress.phone';
backendFieldMapping['addressList[1].vatId'] = 'deliveryAddress.vatId';

backendFieldMapping['addressList[2].name'] = 'billingAddress.name';
backendFieldMapping['addressList[2].title'] = 'billingAddress.title';
backendFieldMapping['addressList[2].givenName'] = 'billingAddress.givenName';
backendFieldMapping['addressList[2].familyName'] = 'billingAddress.familyName';
backendFieldMapping['addressList[2].street'] = 'billingAddress.street';
backendFieldMapping['addressList[2].postalCode'] = 'billingAddress.postalCode';
backendFieldMapping['addressList[2].city'] = 'billingAddress.city';
backendFieldMapping['addressList[2].countryCode'] = 'billingAddress.countryCode';
backendFieldMapping['addressList[2].email'] = 'billingAddress.email';
backendFieldMapping['addressList[2].phone'] = 'billingAddress.phone';
backendFieldMapping['addressList[2].vatId'] = 'billingAddress.vatId';
