import { fadeIn, fadeOut } from "../../utilities/helpers";

export class Authentication {

  login() {
    const loginForms = document.querySelectorAll('.login-form, .submitLogin');
    loginForms.forEach((form: HTMLFormElement) => {
      form.addEventListener('submit', (event) => {
        event.preventDefault();
        event.stopPropagation();
        const email = (form.querySelector('input[name="email"]') as HTMLInputElement ).value;
        const pw = (form.querySelector('input[name="password"]') as HTMLInputElement ).value;
        const dataStringQuery = 'email=' + encodeURIComponent(email) + '&password=' + encodeURIComponent(pw);

        window.fetch('/user/condition', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: dataStringQuery,
        })
        .then(response => response.text())
        .then(data => {
          if (data && data.length > 0) {
            const response = JSON.parse(data);
            const terms = response.termsAndConditionsContent;
            const modalContent = document.getElementById('single-content');
            const modalHeadline = document.getElementById('modal-termsandcondition-headline2');

            modalContent && (modalContent.innerHTML = terms);

            modalContent && (modalContent.style.display = 'block');
            modalHeadline && (modalHeadline.style.display = 'block');

            const acceptTermsCheck = document.getElementById('acceptTermsCheck') as HTMLInputElement;
            const acceptTermsSubmit = document.getElementById('acceptTermsSubmit') as HTMLButtonElement;
            if (acceptTermsCheck && acceptTermsSubmit) acceptTermsCheck.addEventListener('change', () => {
              if (acceptTermsCheck.checked) {
                acceptTermsSubmit.disabled = false;
                acceptTermsSubmit.classList.remove('disabled');
              } else {
                acceptTermsSubmit.disabled = true;
                acceptTermsSubmit.classList.add('disabled');
              }
            });
            acceptTermsSubmit && acceptTermsSubmit.addEventListener('click', () => {
              const hiddenField = document.createElement('input');
              hiddenField.setAttribute('type', 'hidden');
              hiddenField.setAttribute('name', 'acceptTerms');
              hiddenField.setAttribute('value', 'true');
              form.append(hiddenField);
              form.submit();
            });

            const modalTerms = document.getElementById('modal-termsandcondition') as HTMLElement;
            const modalBackground = document.querySelector('.modal-background') as HTMLElement;
            modalTerms && fadeIn(modalTerms);
            modalBackground && fadeIn(modalBackground);
            const modalClose = document.querySelectorAll('.modal-close, .modal-background');
            modalClose.forEach((close) => {
              close.addEventListener('click', () => {
                modalTerms && fadeOut(modalTerms);
                modalBackground && fadeOut(modalBackground);
              });
            });
          } else {
            form.submit();
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        });
      });
    });
  }

  logout() {
    const toggleLogout = document.querySelector('.toggle-logout');
    toggleLogout && toggleLogout.addEventListener('click', () => {
      // prevent browser back button after logout...
      history.pushState(null, null, location.href);

      window.onpopstate = () => {
        history.go(1);
      };
    });
  }

  init() {
      this.login();
      this.logout();
  }
}
