


export class SmartSupply {

    protected smartSupply : HTMLDivElement;

    constructor() {

        this.smartSupply = document.querySelector('.smartsupply') as HTMLDivElement;

        if (!this.smartSupply) {
            return;
        }

        this.validateStandIn();
    }

    validateStandIn() {

        const standIn = this.smartSupply?.querySelector('.stand-in');

        if (!standIn) {
            return;
        }

        const standInInputField : HTMLInputElement = this.smartSupply.querySelector('#input-field-standIn');
        const standInValue : string = standInInputField?.value;
        const saveBtn : HTMLButtonElement = this.smartSupply.querySelector('[value="save"]');

        standInInputField?.addEventListener('change', (event: Event) => {

            const target = event.target as HTMLInputElement;

            if(target.value !== '' && standInValue !== target.value) {
                saveBtn?.removeAttribute('disabled');
            }
        })
    }
}