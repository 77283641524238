export function fadeIn(element: HTMLElement, display = 'block', fromWhere?: string) {
    let cleared: boolean = false;
    let started: boolean = false;
    let transitioned: boolean = false;

    if (element.offsetParent !== null) {
        return false;
    }

    if (fromWhere === undefined) {
        fromWhere = 'none';
    }

    function rec(element: HTMLElement, display: string, fromWhere: string) {
        function clear() {
            element.style.transition = '';
            element.style.display = 'none';
            element.style.visibility = 'invisible';

            cleared = true;
        }
        function setStart() {
            if(fromWhere === 'below') {
                element.style.transform = 'translateY(-2rem)';
            }
            if(fromWhere === 'above') {
                element.style.transform = 'translateY(2rem)';
            }
            element.style.opacity = '0';

            started = true;
        }
        function setTransition() {
            element.style.transition = 'all .2s ease-out';
            element.style.display = display;
            element.style.visibility = 'visible';

            transitioned = true;
        }
        function setEnd() {
            if(fromWhere !== 'none') {
                element.style.transform = 'translateY(0)';
            }
            element.style.opacity = '1';
        }

        if(!cleared) {
            clear();
            setTimeout(() => {
                rec(element, display, fromWhere);
            }, 30)
        } else if(cleared && !started) {
            setStart();
            setTimeout(() => {
                rec(element, display, fromWhere);
            }, 30)
        } else if(cleared && started && !transitioned) {
            setTransition();
            setTimeout(() => {
                rec(element, display, fromWhere);
            }, 30)
        } else if(cleared && started && transitioned) {
            setEnd();
        }
    }

    rec(element, display, fromWhere);
}
export function fadeOut(element: HTMLElement, display= 'block', toWhere?: string) {
    let cleared: boolean = false;
    let started: boolean = false;
    let transitioned: boolean = false;

    if (toWhere === undefined) {
        toWhere = 'none';
    }

    function rec(element: HTMLElement, display: string, toWhere: string) {
        function clear() {
            element.style.transition = '';
            element.style.display = display;
            element.style.visibility = 'visible';

            cleared = true;
        }
        function setStart() {
            if(toWhere !== 'none') {
                element.style.transform = 'translateY(0)';
            }
            element.style.opacity = '1';
            element.style.transition = 'all .2s ease-out';

            started = true;
        }
        function setTransition() {
            if(toWhere === 'below') {
                element.style.transform = 'translateY(-2rem)';
            }
            if(toWhere === 'above') {
                element.style.transform = 'translateY(2rem)';
            }
            element.style.opacity = '0';

            setTimeout(() => {
                transitioned = true;
            }, 200)
        }
        function setEnd() {
            element.style.display = 'none';
            element.style.visibility = 'invisible';
        }

        if(!cleared) {
            clear();
            setTimeout(() => {
                rec(element, display, toWhere);
            }, 30)
        } else if(cleared && !started) {
            setStart();
            setTimeout(() => {
                rec(element, display, toWhere);
            }, 30)
        } else if(cleared && started && !transitioned) {
            setTransition();
            setTimeout(() => {
                rec(element, display, toWhere);
            }, 30)
        } else if(cleared && started && transitioned) {
            setEnd();
        }
    }

    if (element?.offsetWidth > 0 && element?.offsetHeight > 0) {
        rec(element, display, toWhere);
    }
}
export function rewrap(element: Element, tagName: string) {
    const newEl = document.createElement(tagName);
    newEl.innerHTML = element.innerHTML;
    element.parentNode.insertBefore(newEl, element);
    element.remove();
}

export function querySelectValue(src: any, name: string) {
  return src.querySelector(name) ? src.querySelector(name).value : undefined;
}

export function querySetValue(src: any, name: string, data: any) {
  src.querySelector(name) && (src.querySelector(name).value = data);
}

export function dynamicClassListener(name: string, callBack: (target: HTMLElement | any) => void) {
  document.addEventListener('click', (e) => {
    if(e.target && (e.target as HTMLElement).classList.contains(name)){
      callBack(e.target);
    }
  });
}

export function isElementInView(element: HTMLElement) {
  const rect = element.getBoundingClientRect();
  const elemTop = rect.top;
  const elemBottom = rect.bottom;
  // const isFullVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);
  const isVisible = elemTop < window.innerHeight && elemBottom >= 0;
  return isVisible;
}

export function refreshCaptchaForElement(el: HTMLElement) {
  window.fetch('/store/captcha/partial')
    .then((response) => {
      return response.text();
    })
    .then((text) => {
      const captchaWrapper = el.querySelector('.captcha-wrapper');
      const responseWrap = document.createElement('div');
      responseWrap.innerHTML = text;
      const newCaptcha = responseWrap.querySelector('.captcha-wrapper');
      captchaWrapper.innerHTML = '';
      for (let i = 0; i < newCaptcha.children.length; i++) {
        captchaWrapper.appendChild(newCaptcha.children[i]);
      }
    });
}

export function getURLParams() {
  const str = window.location.search;
  let objURL = {} as any;

  str.replace(
    new RegExp( '([^?=&]+)(=([^&]*))?', 'g' ),
    function( $0, $1, $2, $3 ): any {
      objURL[ $1 ] = $3;
    }
  );
  return objURL;
}

export function truncateText(wrapper: HTMLElement) {
    const expander:HTMLElement = wrapper.querySelector('.expander');
    let expanded = false;
    let toExpand: HTMLElement;

    function adjustHeight() {
        expanded && (wrapper.style.maxHeight = toExpand.offsetHeight + 40 + 'px');
    }

    if (expander) {
        toExpand = wrapper.querySelector('.to-expand');
        let innerHeight = toExpand.offsetHeight + 40,
            innerHeightAfterResize = 0,
            startHeight = parseInt(wrapper.getAttribute('data-startheight'));

        if (startHeight > innerHeight) {
            expander.style.opacity = '0';
            expander.style.pointerEvents = 'none';
            wrapper.style.maxHeight = innerHeight + 'px';
            expanded = true;
            expander.classList.add('expanded');
        } else {
            wrapper.style.maxHeight = startHeight + 'px';
            expander.style.opacity = '1';
            expander.style.pointerEvents = 'all';
        }

        window.addEventListener('resize', () => {
            innerHeightAfterResize = toExpand.offsetHeight + 40;

            if (startHeight < innerHeight && innerHeightAfterResize > innerHeight) {
                expander.style.opacity = '1';
                expander.style.pointerEvents = 'all';
                expanded = false;
                expander.classList.remove('expanded');
            }

            innerHeight = toExpand.offsetHeight + 40;
        });

        expander.addEventListener('click', () => {


            if(!expanded) {
                if(startHeight < innerHeight) {
                    if(innerHeight < 800) {
                        expander.style.opacity = '0';
                        expander.style.pointerEvents = 'none';
                        wrapper.style.maxHeight = innerHeight + 'px';
                    } else {
                        window.addEventListener('scroll', adjustHeight)
                        wrapper.style.maxHeight = innerHeight + 'px';
                    }
                    expanded = true;
                    expander.classList.add('expanded');
                }
            } else {
                window.removeEventListener('scroll', adjustHeight)
                wrapper.style.maxHeight = startHeight + 'px';
                expander.style.opacity = '1';
                expander.style.pointerEvents = 'all';
                expanded = false;
                expander.classList.remove('expanded');
                window.scrollTo(window.scrollX, window.scrollY - (innerHeight - startHeight));
            }
        });
    }
}
export function scrollToElement<TElement extends HTMLElement>(element: TElement, offsetY: number): void {

    const finalPosition = element.getBoundingClientRect().top + window.pageYOffset + offsetY;

    const scrollToOptions = {} as ScrollToOptions;
    scrollToOptions.top = finalPosition;
    scrollToOptions.behavior = 'smooth';

    window.scrollTo(scrollToOptions);
}

export function openFilterByOpenedFilterFromSession() {
    const filterHeadlines :NodeListOf<HTMLElement> = document.querySelectorAll('.filter h4');
    const openFilters = sessionStorage.getItem('openFilters');
    const parsedOpenFilters = JSON.parse(openFilters);

    if(!parsedOpenFilters || !filterHeadlines) return

    filterHeadlines.forEach(headline => {
        if(parsedOpenFilters.includes(headline.innerText.trim())) {
            headline.classList.add('active');

            const priceFilterWrapper = headline.parentNode.querySelector('.filter-range-input-wrapper')
            if(priceFilterWrapper) {
                const minPrice :HTMLInputElement = priceFilterWrapper.querySelector('[data-filter-type=min]')
                const maxPrice :HTMLInputElement = priceFilterWrapper.querySelector('[data-filter-type=max]')

                if(minPrice && minPrice.value === '') {
                    minPrice.value = minPrice.min
                }
                if(maxPrice && maxPrice.value === '') {
                    maxPrice.value = maxPrice.max
                }
            }
        }
    });

}
