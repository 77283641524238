import { fadeIn, fadeOut } from '../../utilities/helpers';
import { disableButton, enableButton } from './helper';

export class Reminder {

  init() {
    const basketReminder = <HTMLButtonElement>document.getElementById('basket-reminder');
    if(basketReminder && !basketReminder.classList.contains('hidden')) {
      const basketReminder = <HTMLButtonElement>document.getElementById('basket-reminder');
      let articleId = document.querySelectorAll('.pda')[0].getAttribute('data-article-id');

      const tooltipClose = document.querySelector('#basket-reminder-tooltip .tooltip-close');
      if(tooltipClose) {
        tooltipClose.addEventListener('click', function () {
          fadeOut(document.getElementById('basket-reminder-tooltip'));
        })
      }

      getReminder(basketReminder, articleId);

      basketReminder.addEventListener('click', () => {
        articleId = document.querySelectorAll('.pda')[0].getAttribute('data-article-id');

        if (basketReminder.classList.contains('setReminder')) {
          setReminder(basketReminder, articleId, 'tooltip');
        }
        if (basketReminder.classList.contains('removeReminder')) {
          removeReminder(basketReminder, articleId, 'tooltip');
        }
      });
    }
  }
}

export function setupTooltip() {
  const tooltip = document.getElementById('basket-reminder-tooltip');
  const tooltipAnchor = document.querySelector('.pda-top');
  const header = (<HTMLElement>document.querySelector('header.head'));

  const anchorPosition = tooltipAnchor.getBoundingClientRect();


  tooltip.style.top = header.offsetHeight + 32 + 'px';
  tooltip.style.left = anchorPosition.left + 'px';
  tooltip.style.width = anchorPosition.width + 'px';

  return tooltip;
}

export function setReminder(btn:HTMLButtonElement, articleId:String, notification:String) {
  let tooltip:any;
  if(notification == 'tooltip') tooltip = setupTooltip();

  disableButton(btn);

  window.fetch('/product/reminder/article/' + articleId, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
      .then(response => response)
      .then(data => {
        if(data.status == 201) {
          if(notification == 'tooltip') {
            tooltip.classList.remove('reminder-removed');
            tooltip.classList.add('reminder-set');

            fadeIn(tooltip);
          } else if(notification == 'toast' && btn.parentElement.classList.contains('basket-reminder-wrapper')) {
            btn.parentElement.classList.add('show-set');
          }

          setTimeout(function () {
            if(notification == 'tooltip') {
              fadeOut(tooltip);
            } else if(notification == 'toast' && btn.parentElement.classList.contains('basket-reminder-wrapper')) {
              btn.parentElement.classList.remove('show-set');
            }

            btn.classList.remove('setReminder');
            btn.classList.add('removeReminder');
            enableButton(btn);
          }, 2500)
        } else if(data.status == 403) {
          if(document.getElementById('modal-reminder') && document.querySelector('.modal-background')) {
            fadeIn(document.getElementById('modal-reminder'));
            fadeIn(document.querySelector('.modal-background'));
            enableButton(btn);
          }
        } else {
          enableButton(btn);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
}
export function removeReminder (btn:HTMLButtonElement, articleId:String, notification:String) {
  let tooltip:any;
  if(notification == 'tooltip') tooltip = setupTooltip();

  disableButton(btn);

  window.fetch('/product/reminder/article/' + articleId, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
      .then(response => response)
      .then(data => {
        if(data.status == 204) {
          if(notification == 'tooltip') {
            tooltip.classList.remove('reminder-set');
            tooltip.classList.add('reminder-removed');

            fadeIn(tooltip);
          } else if(notification == 'toast' && btn.parentElement.classList.contains('basket-reminder-wrapper')) {
            btn.parentElement.classList.add('show-removed');
          }

          setTimeout(function () {
            if(notification == 'tooltip') {
              fadeOut(tooltip);
            } else if(notification == 'toast' && btn.parentElement.classList.contains('basket-reminder-wrapper')) {
              btn.parentElement.classList.remove('show-removed');
            }

            btn.classList.remove('removeReminder');
            btn.classList.add('setReminder');
            enableButton(btn);
          }, 2500)
        } else {
          enableButton(btn);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
}

export function getReminder (btn:HTMLButtonElement, articleId:String) {
  //const basketReminder = document.getElementById('basket-reminder');
  //let articleId;
  //articleId = document.querySelectorAll('.pda')[0].getAttribute('data-article-id');

  window.fetch('/product/reminder/article/' + articleId, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
      .then(response => response)
      .then(data => {
        if(data.status == 404 || data.status == 403) {
          btn.classList.remove('removeReminder');
          btn.classList.add('setReminder');
        } else {
          btn.classList.remove('setReminder');
          btn.classList.add('removeReminder');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
}
