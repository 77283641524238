export function updatePriceListName(): void {
    let articlePriceListName: HTMLElement | any = document.querySelectorAll('#articlePriceListName')[0];
    articlePriceListName && (articlePriceListName = articlePriceListName.textContent);

    if (articlePriceListName && articlePriceListName.length > 0) {
        const articleId = document.querySelectorAll('#article-id')[0];
        const pda = document.querySelectorAll('.pda')[0];
        const newArticleIdString = articleId.textContent + '-' + articlePriceListName.split('-')[0];

        articleId.innerHTML = newArticleIdString;
        pda.setAttribute('data-article-pricelist', articlePriceListName.split('-')[0])
    }
}

export function resetFields() {
    const soldOut = document.querySelector('#sold_out');
    soldOut && soldOut.classList.add('hidden');
    const available = document.querySelector('#available');
    available && available.classList.add('hidden');
    const notAvailable = document.querySelector('#not_available');
    notAvailable && notAvailable.classList.add('hidden');
    const quantity = document.querySelector('#quantity');
    quantity && quantity.classList.add('hidden');
    const soldOutMsg = document.querySelector('#sold-out-msg');
    soldOutMsg && soldOutMsg.classList.add('hidden');
    const notAvailableMsg = document.querySelector('#not-available-msg');
    notAvailableMsg && notAvailableMsg.classList.add('hidden');
    const basketEnabled = document.querySelector('#basket-enabled');
    basketEnabled && basketEnabled.classList.add('hidden');
    const basketDisabled = document.querySelector('#basket-disabled');
    basketDisabled && basketDisabled.classList.add('hidden');
    const todoListEnabled = document.querySelector('#todo-list-enabled');
    todoListEnabled && todoListEnabled.classList.add('hidden');
    const todoListDisabled = document.querySelector('#todo-list-disabled');
    todoListDisabled && todoListDisabled.classList.add('hidden');
    const notCompleteSelection = document.querySelector('#not_complete_selection');
    notCompleteSelection && notCompleteSelection.classList.add('hidden');
    const stickyBasketCta = document.querySelector('#sticky-basket-cta');
    stickyBasketCta && stickyBasketCta.classList.remove('cta-inactive');
    stickyBasketCta && stickyBasketCta.classList.add('cta-primary');
}

export function refreshDynamicGenericContent(articleId: string) {
    document.querySelectorAll('.dynamic-generic-content').forEach(function (element: HTMLElement) {
        const urlPrefix = document.querySelector('[data-param-id="urlPrefix"]').innerHTML;
        window.fetch(urlPrefix + '/product/article/' + articleId + '/dynamic/' + element.dataset.name)
          .then(function(response) {
            return response.text();
          })
          .then(function(body) {
            element.innerHTML = body;
          });
    });
}

export function updateInc(newVal: string, max?: string) {
    const incInput = document.querySelectorAll('.pda-cta-quantity .form-field-incremeter-field');
    incInput.forEach((input: HTMLElement) => {
        (input.querySelector('.js-pda-quantity') as HTMLInputElement).value = newVal;
        const quantity = parseInt(newVal);
        const dec = input.closest('.form-field-quantity').querySelector('.incrementer-dec');
        const inc = input.closest('.form-field-quantity').querySelector('.incrementer-inc');
        if (quantity <= 1) dec.classList.add('incrementer-inactive');
        if (quantity > 1) dec.classList.remove('incrementer-inactive');
        if (quantity >= parseInt(max)) inc.classList.add('incrementer-inactive');
        if (quantity < parseInt(max)) inc.classList.remove('incrementer-inactive');
    });
}

export function disableButton(btn: HTMLButtonElement) {
    btn.classList.remove('btn-default');
    btn.classList.add('btn-clickevent');
    btn.disabled = true;
    btn.style.cursor = 'not-allowed';
    btn.style.opacity = '.65';
}

export function enableButton(btn: HTMLButtonElement) {
    btn.classList.remove('btn-clickevent');
    btn.classList.add('btn-default');
    btn.disabled = false;
    btn.style.cursor = 'pointer';
    btn.style.pointerEvents = 'auto';
    btn.style.opacity = '1';
}