/**
 * Get parameter from html elements
 * @returns      A map with parameter
 */
export default function getHtmlParam(selector: string): Map<any, any> {
    let param = new Map();
    let paramList = document.querySelectorAll(selector);

    if(typeof paramList !== 'undefined') {
        paramList.forEach((item: HTMLElement) => {
            let paramId: string = item.getAttribute('data-param-id');
            let paramValue: string = item.textContent.trim();

            param.set(paramId, paramValue);
        });
    }

    return param;
}