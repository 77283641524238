import sendData from '../../utilities/xhr';
import { windowLoaded } from "../../utilities/domready";
import { updateWishlists, addToWishlist } from '../product/towishlist';
import {fadeIn, fadeOut, isElementInView} from "../../utilities/helpers";
import { Validation } from '../../utilities/validation';
import { formSerialize } from '../../utilities/springSerialize';
import getHtmlParam from '../../utilities/htmlParam';

declare var productTableFullFeatureArticleMap: any;

let param = getHtmlParam('.js-param .param');

export class Widgets {

    productTable() {
        function setListeners(row:HTMLElement) {
            const selects: Array<HTMLSelectElement> = Array.from(row.querySelectorAll('select'));
            selects.forEach(select => select.addEventListener('change', () => {
                const getUrl = window.location;
                const baseUrl = getUrl .protocol + '//' + getUrl.host + '/' + getUrl.pathname.split('/')[0];

                const values:Array<Array<String>> = [];
                selects.forEach(select => values.push([select.options[select.selectedIndex].dataset.featureLabel, select.options[select.selectedIndex].value]));
                const singleValueSelects: Array<HTMLSelectElement> = Array.from(row.querySelectorAll('.singleValueSelect'));
                singleValueSelects.forEach(span => values.push([span.dataset.featureLabel, span.innerHTML]));
                values.sort();
                let featureHash: string = '';
                values.forEach(val => {
                    featureHash += val[1] + '|';
                })
                const articleId = productTableFullFeatureArticleMap[row.dataset.pid][featureHash] || null;

                let selectedFeatureValues = '';
                selects.forEach(select => {
                    selectedFeatureValues += selectedFeatureValues = '"' + select.name + '": "' + select.options[select.selectedIndex].value + '",';
                });

                let dynFeats: string[];
                row.dataset.dynfeats == '' ? dynFeats = [] : dynFeats = row.dataset.dynfeats.split(',');

                const featureValues = '{' + selectedFeatureValues.slice(0, -1) + '}';

                const data: any = {
                    "productId": row.dataset.pid,
                    "selectedArticleId": articleId,
                    "dynamicColumns": row.dataset.dyncols.split(','),
                    "dynamicFeatures": dynFeats,
                    "dropdownRequestModel": {
                        "lastSelectedFeatureName": select.name,
                        "selectedFeatureValues": JSON.parse(featureValues)
                    }
                };

                const XHR = new XMLHttpRequest();
                XHR.onreadystatechange = () => {
                    if (XHR.readyState === XMLHttpRequest.DONE && XHR.status === 200) {
                        let newEl = document.createElement('table');
                        newEl.innerHTML = XHR.responseText;
                        const newRow = (<HTMLElement>newEl.querySelector('tr'));
                        row.parentElement.replaceChild(newRow, row);
                        setListeners(newRow);

                        if(newRow.dataset.fallbackArticle === 'true') {
                            newRow.querySelector('.is-fallback-article').classList.add('flyIn');
                            setTimeout(() => {
                                newRow.querySelector('.is-fallback-article').classList.remove('flyIn');
                            }, 2500);
                        }
                    }
                }
                XHR.open( 'POST', '/product/product-table-widget/row');
                XHR.setRequestHeader( 'Content-Type', 'application/json;charset=UTF-8' );
                XHR.send( JSON.stringify(data) );

            }));

            const toBasket: HTMLElement = row.querySelector('.product-table-basket');
            toBasket.addEventListener('click', (e) => {
                e.preventDefault();

                const quantity = (row.querySelector('.basket-quantity') as HTMLInputElement).value;
                const articleId = row.dataset.aid;
                let eventId = '';

                const dataPost = 'quantity=' + quantity + '&articleId=' + articleId + '&ti=' + eventId + '&type=cart';

                window.fetch('/order/cart/item', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: dataPost,
                })
                    .then(response => response)
                    .then(data => {

                        window.fetch('/order/cart/flyout?type=cart&layout=v2')
                            .then(response => response.text())
                            .then((data: any) => {
                                document.querySelectorAll('.flyout-cart')[0].innerHTML = data;

                                const flyoutItemCount = (document.querySelectorAll('.flyout-cart .flyout-cart-item-count')[0] as HTMLElement).innerText;
                                document.querySelectorAll('.js-head-cart-itemcount').forEach((item: HTMLElement) => item.innerHTML = flyoutItemCount);

                                toBasket.parentElement.querySelector('.variant-to-cart-success').classList.add('flyIn');
                                setTimeout(() => toBasket.parentElement.querySelector('.variant-to-cart-success').classList.remove('flyIn'), 2500);
                            });
                    })
            })

            const toWishlist: HTMLElement = row.querySelector('.wishlist-table-cta');
            toWishlist.addEventListener('click', (e) => {
                e.preventDefault();
                updateWishlists('product-table', row.closest('.product-list-row'));
                fadeIn(document.querySelector('.modal-background'));
                fadeIn(document.querySelector('#modal-wishlist'));
            });

            const quantityInput = row.querySelector('.basket-quantity') as HTMLInputElement;
            quantityInput.addEventListener('change', () => {
                updatePrice(quantityInput.closest('.product-list-row'), quantityInput.value);
            });
        }

        function updatePrice(el: HTMLElement, count: string) {
          const articleId = el.dataset.aid;
          const priceContainer = el.querySelector('.variant-price');
          const noScaledPrice = priceContainer.querySelector('.no-scaled-price');
          if (noScaledPrice) return;
          window.fetch(param.get('urlPrefix') + '/pricing/article/' + articleId + '?quantity=' + count + '&profile=' + param.get('profile') + '&language=' + param.get('clientLocale') + '&legalCountry=' + param.get('legalCountry') + '&deliveryCountry=' + param.get('deliveryCountry') + '&identitySub=' + param.get('identitySub') + '&tenant=' + param.get('tenant') + '&username=' + param.get('identUsername') + '&isGuest=' + param.get('getIdentIsGuest') + '&design=variant')
            .then((res) => {
              return res.text()
            })
            .then(text => {
              const html = document.createRange().createContextualFragment(text);
              const price = html.querySelector('.variant-price');
              price && priceContainer && (priceContainer.innerHTML = price.textContent);
            })
          return false;
        }

        windowLoaded(() => {
            if(document.querySelector('.product-list') || document.querySelector('.table-v2')) {
                const productTables: Array<HTMLElement> = Array.from(document.querySelectorAll('.product-list, .table-v2'));
                productTables.forEach(table => {
                    const rows: Array<HTMLElement> = Array.from(table.querySelectorAll('tbody tr'));

                    const tableEl: HTMLElement = table.querySelector('table');
                    const tableWrapper: HTMLElement = <HTMLElement>table.children[0];
                    const scrollBar: HTMLElement = table.parentElement.querySelector('.scroll-indicator');
                    const scrollHandle: HTMLElement = scrollBar.querySelector('span');
                    let maxLeft = 0;
                    let scrollDiff = 0;
                    let pos = { left: 0, x: 0 };
                    let scroll: any = 0;


                    const initScrollBehaviour = () => {
                        if(tableEl.offsetWidth > tableWrapper.offsetWidth) {
                            scrollBar.style.display = 'block';
                            scrollHandle.style.width = (tableWrapper.offsetWidth / tableEl.offsetWidth * 100) + '%';

                            const sW = scrollBar.offsetWidth;
                            const sHW = scrollHandle.offsetWidth;
                            // Maximum Scroll Offset on custom Scrollbar
                            maxLeft = sW - sHW;
                            // Maximum Scroll Offset on Table
                            scrollDiff = tableEl.offsetWidth - tableWrapper.offsetWidth;
                            table.classList.add('shadow-right');
                        } else {
                            scrollBar.style.display = 'none';
                            table.classList.remove('shadow-right');
                        }
                    }
                    initScrollBehaviour();

                    const scrollHandler = (e: any) => {
                        scroll = tableWrapper.scrollLeft;
                        // if(scroll > scrollDiff) {
                        //    tableWrapper.scrollLeft = scrollDiff;
                        // }

                        if(tableWrapper.scrollLeft === 0) {
                            table.classList.remove('shadow-left');
                        } else if(tableWrapper.scrollLeft !== scrollDiff) {
                            table.classList.add('shadow-left');
                        }
                        if(tableWrapper.scrollLeft >= scrollDiff) {
                            table.classList.remove('shadow-right');
                        } else if(tableWrapper.scrollLeft !== 0) {
                            table.classList.add('shadow-right');
                        }

                        let handleLeft = scroll / scrollDiff * maxLeft;
                        if(handleLeft <= 0) {
                            handleLeft = 0;
                        }
                        if(handleLeft >= maxLeft) {
                            handleLeft = maxLeft;
                        }
                        scrollHandle.style.left = handleLeft + 'px';
                    }

                    const mouseMoveHandler = (e: any) => {
                        if(e.type === 'mousemove') {
                            // How far the mouse has been moved
                            const dx = e.clientX - pos.x;

                            // Scroll the element
                            tableWrapper.scrollLeft = pos.left - dx;

                            // Set Scroll Maximum
                            scroll = pos.left - dx;
                            if (scroll > scrollDiff) {scroll = scrollDiff;}
                        }
                        scrollHandler(e);
                    };
                    const mouseUpHandler = (e: any) => {
                        tableWrapper.style.cursor = 'grab';
                        tableWrapper.style.removeProperty('user-select');

                        document.removeEventListener('mousemove', mouseMoveHandler);
                        document.removeEventListener('mouseup', mouseUpHandler);
                    };

                    const mouseDownHandler = (e: any) => {
                        // Change the cursor and prevent user from selecting the text
                        table.style.cursor = 'grabbing';
                        table.style.userSelect = 'none';

                        pos = {
                            // The current scroll
                            left: tableWrapper.scrollLeft,
                            // Get the current mouse position
                            x: e.clientX,
                        };

                        document.addEventListener('mousemove', mouseMoveHandler);
                        document.addEventListener('mouseup', mouseUpHandler);
                    };

                    // Event Listener for Mouse Events
                    tableWrapper.addEventListener('mousedown', mouseDownHandler);
                    // Event Listener for Touchpad Scroll & Touch Scroll
                    tableWrapper.addEventListener('scroll', scrollHandler);
                    // Event Listener for Resize -> Recalculation of Scroll Dimensions
                    window.addEventListener('resize', initScrollBehaviour);


                    // Set Click Handlers on Row Items (Selects, AddToCart, AddToWishlist)
                    if(table.classList.contains('product-list')) {
                        rows.forEach(row => setListeners(row));
                    }
                });

            }
        });
    }

    tabs() {
        const tabHeaders = document.querySelectorAll('.widget .tab-header, .tabs-header-wrapper .tab-header');
        tabHeaders.forEach((tab) => {
            tab.addEventListener('click', () => {
                event.preventDefault();
                const contentId = tab.getAttribute('data-tab-index');

                const tabHeader = tab.parentElement.querySelectorAll('.tab-header')
                tabHeader.forEach((tab) => {
                    tab.classList.remove('active');
                });
                const content = document.getElementById(contentId);
                const parentTabContent = content.parentElement.querySelectorAll('.tab-content');
                parentTabContent.forEach((tab) => {
                    tab.classList.remove('active');
                });

                tab.classList.add('active');
                content && content.classList.add('active');
            });
        });
    }

    scrollToHash() {
        const hashLinks = document.querySelectorAll('a[href^="#"]');
        hashLinks.forEach((link: HTMLAnchorElement) => {
            link.addEventListener('click', () => {
                if (link.getAttribute('href').substring(0, 1) !== '#') return;
                event.preventDefault();
                const url = link.getAttribute('href');
                const hash = (url.substring(url.indexOf('#'))).substring(1);
                if (hash.length >= 2) {
                    const p = document.getElementById(hash);
                    const headWrapperHeight = (document.querySelector('.head-wrapper') as HTMLElement).offsetHeight;
                    const offset = p.getBoundingClientRect().top + window.pageYOffset - headWrapperHeight;

                    window.scroll({ top: offset, behavior: 'smooth' });
                }
            });
        });
    }

    loadVideoAsync() {
        const iframeVideos = document.querySelectorAll('iframe[data-src]');

        iframeVideos.forEach((video: HTMLIFrameElement) => {
          let limit = true;
          document.addEventListener('scroll', () => {
            const videoInView = isElementInView(video as HTMLElement);
            if (videoInView && limit) {
              video.src = video.dataset.src;
              limit = false;
            }
          });
        });
    }

    expandableRows() {
        function truncateText(element: HTMLElement) {
            const expander:HTMLElement = element.querySelector('.expander');

            if (expander) {
                let toExpand:HTMLElement = element.querySelector('.to-expand'),
                    content:HTMLElement = element.querySelector('.expand-content'),
                    innerHeight = content.offsetHeight;

                window.addEventListener('resize', () => {
                    innerHeight = content.offsetHeight;
                });

                expander.addEventListener('click', () => {
                    expander.style.opacity = '0';
                    expander.style.pointerEvents = 'none';
                    element.style.maxHeight = innerHeight + 'px';
                    toExpand.style.maxHeight = innerHeight + 'px';
                });
            }
        }

        // Truncate description & techdata
        if(document.querySelector('.widget.widget-multicolumn')) {
            const multiColumns = [].slice.call(document.querySelectorAll('.widget.widget-multicolumn'));

            multiColumns.forEach((column: HTMLElement) => {
                truncateText(column);
            })
        }

        const expandBox = document.querySelector('.category-suggestions-list') as HTMLElement;
        expandBox && truncateText(expandBox);
    }

    mobileToggleSidenav() {
        if(document.querySelector('.pillar-page-wrapper .category-sidenav') && document.querySelector('.toggle-mobile-sidenav')) {
            const toggles = [].slice.call(document.querySelectorAll('.toggle-mobile-sidenav'));
            const closeBtn = document.querySelector('.category-sidenav .head-mobile svg');

            toggles.forEach((toggle: HTMLElement) => {
                toggle.addEventListener('click', function () {
                    document.body.classList.add('category-sidenav-open');
                })
            })
            closeBtn.addEventListener('click', function () {
                document.body.classList.remove('category-sidenav-open');
            })
        }
    }

    mobileToggleFrequentlySearch() {
        const showMoreBtn = document.querySelector('.show-more-button-nav-list');
        const input :HTMLInputElement = document.querySelector('.show-more-button-nav-list input');
        const showMoreText = (document.querySelector('.i18n-properties .show-more-frequently-search') as HTMLElement)?.innerText.trim();
        const showLessText = (document.querySelector('.i18n-properties .show-less-frequently-search') as HTMLElement)?.innerText.trim();
        const smartPhoneMaxWidth: number = 430;
        const tabletMaxWidth: number = 1024;
        const smartPhoneMaxWidthQuery = window.matchMedia(`(max-width: ${smartPhoneMaxWidth}px)`);
        const tabletMaxWidthQuery = window.matchMedia(`(max-width: ${tabletMaxWidth}px)`);

        if(showMoreBtn && input && showMoreText && showLessText) {
            showMoreBtn.addEventListener('click', e => {

                if (showMoreBtn.classList.contains('closed')) {
                    const items :NodeListOf<HTMLElement>  = document.querySelectorAll('.nav-list-item');

                    for (let i = 0; i < items.length; i++ ) {
                        items[i].style.display = 'block';
                    }
                    input.value = showLessText;
                    showMoreBtn.classList.add('opened');
                    showMoreBtn.classList.remove('closed');
                } else if (showMoreBtn.classList.contains('opened')){
                    if (smartPhoneMaxWidthQuery.matches) {
                        const items :NodeListOf<HTMLElement>  = document.querySelectorAll('.nav-list-item');

                        for (let i = 1; i < items.length; i++ ) {
                            items[i].style.display = 'none';
                        }
                        input.value = showMoreText;
                        showMoreBtn.classList.add('closed');
                        showMoreBtn.classList.remove('opened');
                        return;
                    }
                    if (tabletMaxWidthQuery.matches) {
                        const items :NodeListOf<HTMLElement>  = document.querySelectorAll('.nav-list-item');

                        for (let i = 2; i < items.length; i++ ) {
                            items[i].style.display = 'none';
                        }
                        input.value = showMoreText;
                        showMoreBtn.classList.add('closed');
                        showMoreBtn.classList.remove('opened');
                    }
                }
            })
        }
    }

    // function to find rows with multiple "navigation_with_picture"-widgets and equal out heights of titles and lists
    navigationWithPictureWidgetsSetEqualHeights() {
        if(document.querySelector('.widget.widget-navigation_with_picture')) {
            const rows = [].slice.call(document.querySelectorAll('.widget.widget-multicolumn .row'));
            interface rowsAndWidgets {
                rows: Array<HTMLElement>;
                widgets: Array<Array<HTMLElement>>;
            }
            let rowsWithWidgets: rowsAndWidgets = {rows: [], widgets: []};

            // check which row contains more than 1 navigation_with_picture widget
            rows.forEach((row: HTMLElement) => {
                const widgetsInRow = [].slice.call(row.querySelectorAll('.widget.widget-navigation_with_picture'));

                // push rows with multiple navigation_with_picture widgets to object
                if(widgetsInRow.length > 1) {
                    rowsWithWidgets.rows.push(row);
                    rowsWithWidgets.widgets.push(widgetsInRow);
                }
            })

            // loop through rows
            rowsWithWidgets.widgets.forEach((array: Array<HTMLElement>) => {
                let maxWidgetHeight: number = 0;
                let maxTitleHeight: number = 0;
                let maxListHeight: number = 0;
                let gotoCategoryCount: number = 0;

                // find highest title & list of widgets in row
                array.forEach((widget) => {
                    let titleHeight = (widget.querySelector('.category-overview a.h2') as HTMLElement).offsetHeight;
                    titleHeight > maxTitleHeight ? maxTitleHeight = titleHeight : null;

                    let listHeight = (widget.querySelector('.category-overview ul') as HTMLElement).offsetHeight;
                    listHeight > maxListHeight ? maxListHeight = listHeight : null;

                    // check if widget contains a goto-category link
                    widget.querySelector('.category-overview a.goto-category') ? gotoCategoryCount++ : null;
                })
                // set titles & lists to max/equal heights
                array.forEach((widget) => {
                    (widget.querySelector('.category-overview a.h2') as HTMLElement).style.height = maxTitleHeight + 'px';

                    // set list heights only if more than 1 widget in row has a goto-category link
                    if(gotoCategoryCount > 1) {
                        (widget.querySelector('.category-overview ul') as HTMLElement).style.height = maxListHeight + 'px';
                    }
                })
                // find highest widget in row
                array.forEach((widget) => {
                    let widgetHeight = (widget.querySelector('.widget.navigation-with-picture ') as HTMLElement).offsetHeight;
                    widgetHeight > maxWidgetHeight ? maxWidgetHeight = widgetHeight : null;
                })
                // set widgets to max/equal heights
                array.forEach((widget) => {
                    (widget.querySelector('.widget.navigation-with-picture ') as HTMLElement).style.height = maxWidgetHeight + 'px';
                })
            })
        }
    }

    catalogOrder() {
      const catalogForm = document.getElementById('catalog-form') as HTMLFormElement;
      if (catalogForm) {
        catalogForm.addEventListener('submit', (e) => {
          e.preventDefault();
          let field = [];
          const len = catalogForm.elements.length;
          for (let i = 0; i < len; i++) {
            const el = catalogForm.elements[i] as HTMLInputElement;
            if (el.checked) field.push(el.value)
          }
          if (field.length) {
            let quantity = '';
            for (let n = 0; n < field.length; ++n) {
              quantity += '1,';
            }
            const params = 'articleId=' + field.join(',') + '&quantity=' + quantity;
            window.fetch('/order/cart', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: params,
              })
              .then(response => response)
              .then(data => {

                fadeIn(document.querySelector('.modal-background'));
                fadeIn(document.getElementById('modal-addedCatalog'));

                window.fetch('/order/cart/flyout?type=cart&layout=v2')
                  .then(response => response.text())
                  .then((data: any) => {
                    document.querySelectorAll('.flyout-cart')[0].innerHTML = data;

                    const flyoutItemCount = (document.querySelectorAll('.flyout-cart .flyout-cart-item-count')[0] as HTMLElement).innerText;
                    document.querySelectorAll('.js-head-cart-itemcount').forEach((item: HTMLElement) => item.innerHTML = flyoutItemCount);

                  });
              });
          }
        });
      }
    }

    hotspot() {
        windowLoaded(() => {
            if(document.querySelector('.hotspot-widget')) {
                const hotspotWidgets: Array<HTMLElement> = Array.from(document.querySelectorAll('.hotspot-widget'));
                hotspotWidgets.forEach(widget => {

                    // Add compact mode class to reduce label sizes for small hotspot widgets (i.e. used in sliders or small columns)
                    if(widget.offsetWidth <= 720) widget.classList.add('compact');

                    // Add class to have hotspots pulse if user is not interacting with widget
                    widget.addEventListener('mouseenter', function () {
                        widget.classList.remove('pulsing');
                    })
                    widget.addEventListener('mouseleave', function () {
                        widget.classList.add('pulsing');
                    })

                    const hotspotD: HTMLElement = widget.querySelector('.hotspot-d > div');
                    const hotspotM: HTMLElement = widget.querySelector('.hotspot-m > div');

                    const boundary = 12;

                    const dHotspots: Array<HTMLElement> = Array.from(widget.querySelectorAll('.hotspot-d > div > [class^="hotspot"]'));
                    const mHotspots: Array<HTMLElement> = Array.from(widget.querySelectorAll('.hotspot-m > div > [class^="hotspot"]'));

                    const hotspotDRect = hotspotD.getBoundingClientRect();
                    const hotspotMRect = hotspotM.getBoundingClientRect();

                    function processLabels(hotspot:HTMLElement, hotspotWidgetDimensions:any) {
                        const label: HTMLElement = hotspot.querySelector('.hotspot-label');

                        // Label stays hidden if empty (R7 product status)
                        if(label.innerHTML.trim() == '') {
                            return;
                        } else {
                            hotspot.style.display = 'block';
                        }

                        const labelRect = label.getBoundingClientRect();
                        const labelX = labelRect.x - hotspotWidgetDimensions.x;
                        const labelY = labelRect.y - hotspotWidgetDimensions.y;
                        const labelXWidth = labelX + labelRect.width;
                        const hotspotWidgetWidth = hotspotWidgetDimensions.width;
                        const hotspotWidgetHeight = hotspotWidgetDimensions.height;

                        // Default label is displayed centered above hotspot
                        // Check if label protrudes widget on the left -> Changes position to display label on right side of hotspot, levelled with hotspot on the top side
                        if(labelX < boundary) {
                            label.classList.remove('pos-default');
                            label.classList.add('pos-right');

                            const newLabelRect = label.getBoundingClientRect();
                            const newLabelY = newLabelRect.y - hotspotWidgetDimensions.y;
                            const newLabelYHeight = newLabelY + newLabelRect.height;

                            // Checks if label in new position protrudes widget on the bottom -> Moves widget up to be levelled with hotspot on the bottom side
                            if(newLabelYHeight > hotspotWidgetHeight - boundary) {
                                label.classList.add('offset-bottom');
                            }

                        // Check if label protrudes widget on the right -> Changes position to display label on left side of hotspot, levelled with hotspot on the top side
                        } else if (labelXWidth > hotspotWidgetWidth - boundary) {
                            label.classList.remove('pos-default');
                            label.classList.add('pos-left');

                            const newLabelRect = label.getBoundingClientRect();
                            const newLabelY = newLabelRect.y - hotspotWidgetDimensions.y;
                            const newLabelYHeight = newLabelY + newLabelRect.height;

                            // Checks if label in new position protrudes widget on the bottom -> Moves widget up to be levelled with hotspot on the bottom side
                            if(newLabelYHeight > hotspotWidgetHeight - boundary) {
                                label.classList.add('offset-bottom');
                            }

                        // Check if label protrudes widget on the top side -> Changes position to display label centered below hotspot
                        } else if (labelY < boundary) {
                            if(widget.id == 'hotspot-widget-1571567' && hotspotWidgetDimensions === hotspotDRect) console.log(labelY, boundary, 'case 3');
                            label.classList.remove('pos-default');
                            label.classList.add('pos-bottom');
                        }
                        // Since the label should always be smaller than widget (otherwise the widget is too small to be usable), the label can't protrude the widget on left/right or top/bottom at the same time, so no further checks are required
                    }

                    dHotspots.forEach(hs => {
                        processLabels(hs, hotspotDRect);
                    })
                    mHotspots.forEach(hs => {
                        processLabels(hs, hotspotMRect);
                    })


                    const toWishlist: Array<HTMLElement> = Array.from(widget.querySelectorAll('.widget-addtowishlist'));
                    let addToWishlistHandlerSet: boolean = false;
                    toWishlist.forEach(btn => {
                        btn.addEventListener('click', (e) => {
                            e.preventDefault();

                            if (!addToWishlistHandlerSet) {
                                // Click Listener for AddToWishlist Button in Wishlist Modal
                                const addToWishlistBtn = document.querySelector('.add-to-wishlist');
                                addToWishlistBtn && addToWishlistBtn.addEventListener('click', (e) => {
                                    e.preventDefault();
                                    addToWishlist('hotspotwidget', btn.getAttribute('data-pid'), btn.getAttribute('data-aid'));
                                });
                                addToWishlistHandlerSet = true;
                            }

                            updateWishlists('hotspotwidget', btn);
                            fadeIn(document.querySelector('.modal-background'));
                            fadeIn(document.querySelector('#modal-wishlist'));
                        })
                    });
                });

            }
        });
    }

    init() {
        this.productTable();
        this.tabs();
        this.scrollToHash();
        this.loadVideoAsync();
        this.expandableRows();
        this.mobileToggleSidenav();
        this.mobileToggleFrequentlySearch();
        this.navigationWithPictureWidgetsSetEqualHeights();
        this.catalogOrder();
        this.hotspot();

        if(document.querySelector('.widget-newsletter')) {
            const validation = new Validation();
            validation.init();
        }
    }
}
